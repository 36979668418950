
import axios from 'axios';
import Vue from 'vue';
import Cookies from 'js-cookie';
// We use this, rather than the full `jsonwebtoken` package,
// b/c we don't need and can't do any verification client-side
// anyway, and we save a bunch of huge encryption dependencies
// by skipping all that.
import { jwtDecode } from 'jwt-decode';
import moment from 'moment';
import config from 'rocketship-config';
import api from '../../api';

const SESSION_PERSISTENCE_ENABLED = true;

const sessionStorageKey = 'hw-auth';
const regCookieSuffix = ':registeredUser';
const newUserCookieSuffix = ':newUser';
const onboardingCookieSuffix = ':onboarding';
const watchedVideoCookieSuffix = ':watchedVideo';

/* LP has a 20 char limit for the value in the key - value pairs for pollOptions
   This will follow similar patterns in the future, but since this is already on PROD
   and the Arrowhead, Ice, Ozarka, Polan, Pure, and Splash polls are currently working,
   it is best to leave them as is.
*/
const pollOptions = {"august":{"arrowhead":"august_24_ah_poll","deer":"august_24_dp_poll","ice":"august_24_im_poll","ozarka":"august_24_oz_poll","poland":"august_24_ps_poll","pure":"august_24_pl_poll","splash":"august_24_sp_poll","zephyr":"august_24_zh_poll"},"september":{"arrowhead":"september_24_ah_poll","deer":"september_24_dp_poll","ice":"september_24_im_poll","ozarka":"september_24_oz_poll","poland":"september_24_ps_poll","pure":"september_24_pl_poll","splash":"september_24_sp_poll","zephyr":"september_24_zh_poll"},"default":{"arrowhead":"september_24_ah_poll","deer":"september_24_dp_poll","ice":"september_24_im_poll","ozarka":"september_24_oz_poll","poland":"september_24_ps_poll","pure":"september_24_pl_poll","splash":"september_24_sp_poll","zephyr":"september_24_zh_poll"}};

const getDefaultState = () => Vue.observable({
    token: getLocalStorageToken(),
});

const cleanProfile = (profile) => {
    if (Object.prototype.hasOwnProperty.call(profile, 'g-recaptcha-response')) {
        // Remove from returned state
        delete profile['g-recaptcha-response'];
    }
    // Remove empty token to prevent overwriting a valid one.
    if (Object.prototype.hasOwnProperty.call(profile, 'token') && !profile.token) {
        delete profile.token;
    }

    return profile;
};

const state = getDefaultState();

const browserTime = makeObservableBrowserTime();

updateAxiosToken(state.token);

const getters = {
    loggedIn: (state) => Object.prototype.hasOwnProperty.call(state, 'id') && state.id && state.id !== 'me',
    tokenExpirationTime: (state) => {
        if (state.token) {
            const { exp } = jwtDecode(state.token);
            // UTC timestamp in seconds.
            return exp;
        }
        return;
    },
    isSessionExpired: (state, getters, rootState) => {
        if (!getters.loggedIn) return;

        const
            currentTime = browserTime.now / 1000,
            { tokenExpirationTime } = getters;

        return tokenExpirationTime <= currentTime;
    },
    returning: (state, getters, rootState) => Cookies.get(rootState.app.name + regCookieSuffix),
    newUser: (state, getters, rootState) => Cookies.get(rootState.app.name + newUserCookieSuffix),
    getOnboardingCookie: async (state, getters, rootState) => {
        const isOnboarding = await Cookies.get(rootState.app.name + onboardingCookieSuffix);
        return isOnboarding;
    },
    watchedVideo: (state, getters, rootState) => Cookies.get(rootState.app.name + watchedVideoCookieSuffix),
    hasCompleteAddress: (state) => !!state.address_line_1 && !!state.city && !!state.state && !!state.zip,
    currentPoll: (state, getters, rootState) => {
        const currentMonth = moment(rootState.app.now).format('MMMM').toLowerCase();
        console.log('rootState.app.now is', rootState.app.now);
        return (pollOptions[currentMonth]) ? pollOptions[currentMonth][state.preferred_brand] : pollOptions.default[state.preferred_brand];
    },
};

const mutations = {
    resetProfile (state) {
        // Remove everything already in state.
        for (const key in state) {
            Vue.delete(state, key);
        }

        // Re-set any default keys.
        const defaultState = getDefaultState();
        for (const key in defaultState) {
            Vue.set(state, key, defaultState[key]);
        }
    },

    // data can be an array (to prevent having to commit multiple mutations in
    // a row), or a flat object
    updateProfile (state, data) {
        if (!Array.isArray(data)) {
            data = [data];
        }

        for (const values of data) {
            for (const key in values) {
                Vue.set(state, key, values[key]);

                if (key === 'token') {
                    const { token } = state;

                    if (token) {
                        setLocalStorageToken(token);
                    }

                    updateAxiosToken(token);
                }
            }
        }
    },

    deletePasswordsFromStore (state) {
        // Removes password data from the vuex store.
        // We don't need to store users' plain text passwords after they've been registered or logged in.
        const passwordFields = ['password', 'confirm_password', 'plainTextPassword'];
        passwordFields.forEach((key) => {
            Vue.delete(state, key);
        });
    },
};

const actions = {
    async loadSession ({ state, dispatch }) {
        if (state.token) {
            dispatch('setRegisteredUserCookie');

            try {
                await dispatch('getProfile');
            }
            catch (err) {
                // Kick the user back to intro to log in again.
                // FIXME: we can do better than a page refresh.
                window.location.href = '/';
            }
        }
    },

    getProfile ({ dispatch }) {
        return dispatch('makeCall', {
            type: 'get',
            endpoint: 'profiles/me',
        });
    },

    logIn ({ dispatch }, { tempProfile }) {
        return dispatch('makeCall', {
            endpoint: 'profiles/login',
            tempProfile,
        });
    },

    logOut ({ dispatch, commit }) {
        removeLocalStorageToken();
        dispatch('removeNewUserCookie');
        commit('resetProfile');
    },

    async register ({ dispatch }, { tempProfile }) {
        const response = await dispatch('makeCall', {
            endpoint: 'profiles',
            tempProfile,
        });

        dispatch('setNewUserCookie');
        dispatch('setOnboardingCookie');

        return response;
    },

    async makeCall ({ state, dispatch, commit }, {
        type = 'post',
        endpoint,
        tempProfile = {},
    }) {
        commit('resetProfile');

        try {
            commit('updateProfile', tempProfile);
            const response = await axios[type](`${api.base}/${endpoint}`, state);
            const profile = cleanProfile(response.data.result.profile);
            commit('updateProfile', profile);
            commit('deletePasswordsFromStore');

            if (profile.token) {
                dispatch('setRegisteredUserCookie');
            }

            return response;
        }
        catch (err) {
            dispatch('logOut');

            console.error(
                `error making ${endpoint} call`,
                err.message,
                err,
            );

            throw err;
        }
    },

    async updatePoll ({ state, commit }, data) {
        try {
            const response = await axios.post(`${api.base}/profiles/poll/${state.id}`, data);
            const profile = cleanProfile(response.data.result.profile);
            commit('updateProfile', profile);
            return profile;
        }
        catch (err) {
            console.error(err);
        }
    },

    async updateProfile ({ state, commit }, data) {
        try {
            const response = await axios.post(`${api.base}/profiles/${state.id}`, data);
            const profile = cleanProfile(response.data.result.profile);
            commit('updateProfile', profile);
            return profile;
        }
        catch (err) {
            console.error(err);
        }
    },

    async checkIfProfileExists ({ commit }, data) {
        commit('updateProfile', data);
        await axios.post(`${api.base}/profiles/exists`, data);
    },

    async recordEvent ({ state }, event_type) {
        try {
            return await axios.post(`${api.base}/profiles/record`, { event_type })
            .then(res => {
                const { points_earned_total } = res.data;
                return points_earned_total;
            });
        }
        catch (err) {
            console.error(err);
            throw err;
        }
    },

    async forgotPassword ({ state }, email) {
        try {
            await axios.post(`${api.base}/profiles/password/forgot`, { email });
        }
        catch (err) {
            console.error(err);
        }
    },

    async communicationsOptOut ({ state, getters, commit }, email) {
        try {
            const response = await axios.post(`${api.base}/profiles/optout`, { email });
            if (getters.loggedIn && email === state.email) commit('updateProfile', { primary_opt_in: false });
            return response;
        }
        catch (err) {
            console.error(err);
            throw err;
        }
    },

    async jmsOptIn ({ commit }) {
        try {
            const { data: { jms_optin } } = await axios.post(`${api.base}/jms`);
            commit('updateProfile', { jms_optin });
        }
        catch (err) {
            console.error(err);
        }
    },

    async resetPassword ({ state }, data) {
        await axios.post(`${api.base}/profiles/password/reset`, { data });
    },

    setRegisteredUserCookie ({ rootState }) {
        Cookies.set(rootState.app.name + regCookieSuffix, 'yes');
    },

    setNewUserCookie ({ rootState }) {
        Cookies.set(rootState.app.name + newUserCookieSuffix, 'yes');
    },

    removeNewUserCookie ({ rootState }) {
        Cookies.remove(rootState.app.name + newUserCookieSuffix);
    },

    setOnboardingCookie ({ rootState }) {
        Cookies.set(rootState.app.name + onboardingCookieSuffix, 'yes');
    },

    removeOnboardingCookie ({ rootState }) {
        Cookies.remove(rootState.app.name + onboardingCookieSuffix);
    },

    setWatchedVideoCookie ({ rootState }) {
        Cookies.set(rootState.app.name + watchedVideoCookieSuffix, 'yes', { expires: 365 });
    },
};

// Send custom auth header with every AJAX request.
function updateAxiosToken (token) {
    if (token) {
        // Not using Authorization header due to review's Basic auth.
        axios.defaults.headers.common['X-HW-Profile-Token'] = token;
    }
    else {
        delete axios.defaults.headers.common['X-HW-Profile-Token'];
    }
}

function getLocalStorageToken () {
    if (!SESSION_PERSISTENCE_ENABLED) return;

    try { return window.localStorage.getItem(sessionStorageKey); }
    catch (err) { console.error('localStorage error', err); }
}

function setLocalStorageToken (token) {
    if (!SESSION_PERSISTENCE_ENABLED) return;

    try { window.localStorage.setItem(sessionStorageKey, token); }
    catch (err) { console.error('localStorage error', err); }
}

function removeLocalStorageToken () {
    try { window.localStorage.removeItem(sessionStorageKey); }
    catch (err) { console.error('localStorage error', err); }
}

// As opposed to `app/now`, which is server time at initial page load.
function makeObservableBrowserTime () {
    // Tick the clock once every second.
    const TICK_INTERVAL = 1000;

    const observableTime = Vue.observable({
        now: Date.now(),
        // In case somebody wants to cancel this.
        intervalId: null,
    });

    observableTime.intervalId = setInterval(() => {
        observableTime.now = Date.now();
    }, TICK_INTERVAL);

    return observableTime;
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
